/* eslint-disable no-unused-expressions */
/**
 * Class representing a Sofitel CardEditorial Slideshow Component instance
 * @author Rishabh
 * @class SofitelCardEditorialSlideshow
 * @classdesc Sofitel Card Editorial Slideshow component to inherit the Card Editorial Slideshow core component
 * @extends CardEditorialSlideshow
 * @version 1.0
 */
import { CardEditorialSlideshow, TagManager } from "@accor/ace-ui-core";

export default class SofitelCardEditorialSlideshow extends CardEditorialSlideshow {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }

    this.slideshowCalloutContainerItem = this.componentHost.querySelectorAll(
      ".cmp-slideshow__callout-item",
    );
    this.callSlideshowTagManagerHandle();
  }

  /**
   * Push data in tagmanager
   */
  callSlideshowTagManagerHandle() {
    let blockName = "";
    let blocInteraction = "";
    if (this.dataLayerJson) {
      this.slideshowCalloutContainerItem?.forEach((element) => {
        element
          ?.querySelector(".cmp-button")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            if (this.dataLayerJson?.pageName === "sofitel::hotels::homepage") {
              // for hotel homepage
              blockName =
                this.componentHost?.parentElement?.previousElementSibling
                  ?.querySelector("div")
                  ?.getAttribute("id");
              blocInteraction = `${element.querySelector(".cmp-slideshow__callout-heading .cmp-slideshow__callout-title")?.innerText?.trim()?.toLowerCase()} details`;
            } else if (
              this.dataLayerJson?.pageName === "sofitel::brand::homepage"
            ) {
              // for homepage
              blockName =
                this.componentHost?.parentElement?.previousElementSibling
                  ?.querySelector(".ace-section-title__content--title")
                  ?.innerText?.trim()
                  ?.toLowerCase();
              blocInteraction = `${element.querySelector(".cmp-slideshow__callout-heading .cmp-slideshow__callout-title")?.innerText?.trim()?.toLowerCase()} details`;
            }
            const dataEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: blockName,
                bloc_interaction: blocInteraction,
              },
            };
            TagManager.trackEvent(dataEvent);
          });
      });
    }
  }
}

// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  CardEditorialSlideshow.CLASS_NAMESPACE,
  SofitelCardEditorialSlideshow,
  true,
);
