/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Callout Component instance
 * @author Rishabh
 * @class SofitelCallout
 * @classdesc Sofitel callout component to inherit the callout core component
 * @extends Callout
 * @version 1.0
 */
import { Callout } from "@accor/ace-ui-core";

export default class SofitelCallout extends Callout {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
  }
  /**
   * overwriting function of parent class with empty code so as to stop core event from getting pushed in sofitel
   */
  calloutResort() {}

  /**
   * overwriting function of parent class with empty code so as to stop core event from getting pushed in sofitel
   */
  setCarouselClickListener() {}
}

// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  Callout.CLASS_NAMESPACE,
  SofitelCallout,
  true,
);
