/**
 * @author Rishabh
 * @fileOverview
 */

import { BasePageTemplate, TagManager } from "@accor/ace-ui-core";
export default class SofitelBasePageTemplate extends BasePageTemplate {
  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      this.TagManagerHandler();
    }
  }

  /**
   * based on page name call tagmanager handlers for components required
   */
  TagManagerHandler() {
    if (this.dataLayerJson?.pageName === "sofitel::brand::homepage") {
      this.callNewsletterButtonTagManager();
      this.callLoyaltyButtonTagManager();
    } else if (this.dataLayerJson?.pageName === "sofitel::hotels::homepage") {
      this.callSocialIconTagManager();
    } else if (this.dataLayerJson?.pageName === "sofitel::brand::loyalty") {
      this.callLoyaltyHighlightTagManager();
    }
  }
  /**
   * Push data in tagmanager
   */
  callNewsletterButtonTagManager() {
    const newsLetter = this.componentHost.querySelectorAll(".title");
    newsLetter?.forEach((element) => {
      if (element?.nextElementSibling?.classList?.contains("button")) {
        element?.nextElementSibling
          ?.querySelector("a")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const dataEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: "stay in touch",
                bloc_interaction: "join",
              },
            };
            TagManager.trackEvent(dataEvent);
          });
      }
    });
  }

  /**
   * Push data in tagmanager
   */
  callLoyaltyButtonTagManager() {
    const loyaltyButton = this.componentHost.querySelectorAll(".image");
    loyaltyButton?.forEach((element) => {
      if (element?.nextElementSibling?.classList.contains("button")) {
        element?.nextElementSibling
          ?.querySelector("a")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const dataEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: "accor live limitless loyalty program",
                bloc_interaction: "discover the program",
              },
            };
            TagManager.trackEvent(dataEvent);
          });
      }
    });
  }

  /**
   * Push data in tagmanager
   */
  callSocialIconTagManager() {
    const socialIconTitle = this.componentHost.querySelectorAll(".title");
    socialIconTitle?.forEach((element) => {
      if (element?.nextElementSibling?.classList.contains("iconlist")) {
        element?.nextElementSibling
          ?.querySelectorAll(".ace-footer-icon__list--item")
          ?.forEach((item) => {
            item
              ?.querySelector("a")
              ?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
                const dataEvent = {
                  event: "GA4event",
                  eventName: "bloc_interact",
                  event_data: {
                    pagename: this.dataLayerJson?.pageName,
                    bloc_name: "social media",
                    bloc_interaction: `${element?.querySelector(".ace-section-title__content--title")?.innerText?.trim()?.toLowerCase()} details`,
                  },
                };
                TagManager.trackEvent(dataEvent);
              });
          });
      }
    });
  }

  /**
   * Push data in tagmanager
   */
  callLoyaltyHighlightTagManager() {
    const highlight = this.componentHost.querySelectorAll(".highlight");
    highlight?.forEach((element) => {
      if (element?.nextElementSibling?.classList?.contains("button")) {
        element?.nextElementSibling
          ?.querySelector("a")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            const dataEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: element
                  ?.querySelector(".ace-highlight__content--title")
                  ?.innerText?.trim()
                  ?.toLowerCase(),
                bloc_interaction: event?.target?.innerText
                  ?.trim()
                  ?.toLowerCase(),
              },
            };
            TagManager.trackEvent(dataEvent);
          });
        element
          ?.querySelector(".ace-button .cmp-button")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            const dataEvent = {
              event: "GA4event",
              eventName: "bloc_interact",
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: event?.target
                  ?.closest(".ace-highlight__content--title")
                  .innerText?.trim()
                  ?.toLowerCase(),
                bloc_interaction: event?.target?.innerText
                  ?.trim()
                  ?.toLowerCase(),
              },
            };
            TagManager.trackEvent(dataEvent);
          });
      }
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  BasePageTemplate.CLASS_NAMESPACE,
  SofitelBasePageTemplate,
  true,
);
