/**
 * @author Rishabh
 * @class SofitelBookingSearch
 * @classdesc JS Class handling Sticky Booking Search component with HTML tag 'sticky-button-search-wrapper'
 */
import { TagManager } from "@accor/ace-ui-core";
export default class SofitelBookingSearch extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "sticky-button-search-wrapper";

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    this.componentHost
      .querySelector(".searching a")
      .addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.tagManagerHandler();
      });
  }

  /**
   * Push data in tagmanager
   */
  tagManagerHandler() {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const dataEvent = {
        event: "GA4event",
        eventName: "bloc_interact",
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          bloc_name: "request proposal",
        },
      };
      TagManager.trackEvent(dataEvent);
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  SofitelBookingSearch.CLASS_NAMESPACE,
  SofitelBookingSearch,
);
