/**
 *  *
 * @author Rishabh
 * @class SofitelBackButton
 * @classdesc JS Class handling Back button component with HTML tag 'ace-backbutton'
 */
import { TagManager } from "@accor/ace-ui-core";

export default class SofitelBackButton extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "ace-backbutton";
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
    this.backButton = this.componentHost.querySelector(".cmp-back-link");
    this.backButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.tagManagerHandler();
    });
  }

  /**
   * Push data in tagmanager
   */
  tagManagerHandler() {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const dataEvent = {
        event: "GA4event",
        eventName: "bloc_interact",
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          bloc_name: "back to hotel",
        },
      };
      TagManager.trackEvent(dataEvent);
    }
  }
}

// Register the Back Button component with the BaseComponent
CoreJS.BaseComponent.registerComponent(
  SofitelBackButton.CLASS_NAMESPACE,
  SofitelBackButton,
);
