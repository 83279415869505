/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Quick Access Component instance
 * @author Rishabh
 * @class QuickAccessV2
 * @classdesc Ace quick access component V2 to inherit the quicka ccess core component
 * @extends QuickAccessV2
 * @version 1.0
 */
import { QuickAccessV2, TagManager } from "@accor/ace-ui-core";

export default class SofitelQuickAccessV2 extends QuickAccessV2 {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.quickAccessNavItems = this.componentHost.querySelectorAll(
      ".cmp-ace-anchor-navigation__tabs__anchors ul li",
    );
    this.tagManagerHandler();
  }

  tagManagerHandler() {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      this.quickAccessNavItems.forEach((element, index) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: "GA4event",
            eventName: "menu_inpage",
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              menu_position: index + 1,
              menu_label: element?.innerText?.trim()?.toLowerCase(),
            },
          };
          TagManager.trackEvent(dataEvent);
        });
      });
    }
  }
}

// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  QuickAccessV2.CLASS_NAMESPACE,
  SofitelQuickAccessV2,
  true,
);
