export default class RoomAmenities extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "ace-room-amenities";

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    const longListCount = 3;
    const amenitiesLists = document.querySelectorAll(".js-room-amenities-list");
    const listsButtons = document.querySelectorAll(
      ".js-ace-room-amenities-button",
    );

    function addButtonAttributesToLongLists(list) {
      const button = list.nextElementSibling;

      button.classList.add("ace-room-amenities-section__button--visible");
      button.setAttribute("aria-hidden", false);
      button.setAttribute("aria-expanded", false);
    }

    function countListItems(list) {
      const listLenght = list.childElementCount;

      if (listLenght > longListCount) {
        addButtonAttributesToLongLists(list);
      }
    }

    function toggleAriaButton(button) {
      button.setAttribute(
        "aria-expanded",
        button.getAttribute("aria-expanded") === "true" ? "false" : "true",
      );

      // see more text + focus
      if (button.children[0].hasAttribute("aria-hidden")) {
        amenitiesLists.forEach((list) => list.removeAttribute("tabindex"));
        button.children[0].removeAttribute("aria-hidden");
      } else {
        amenitiesLists.forEach((list) => list.removeAttribute("tabindex"));
        button.children[0].setAttribute("aria-hidden", true);
        button.previousElementSibling.setAttribute("tabindex", -1);
      }

      // see less text
      if (button.children[1].hasAttribute("aria-hidden")) {
        button.children[1].removeAttribute("aria-hidden");
      } else {
        button.children[1].setAttribute("aria-hidden", true);
      }
    }

    function toggleExpandList(e) {
      const button = e.currentTarget;
      const listContent = button.previousElementSibling;

      listContent.classList.toggle(
        "ace-room-amenities-section__list--expanded",
      );
      button.classList.toggle("ace-room-amenities-section__button--expanded");
      toggleAriaButton(button);
    }

    amenitiesLists.forEach((list) => countListItems(list));
    listsButtons.forEach((item) =>
      item.addEventListener(CoreJS.DomEventConstants.CLICK, toggleExpandList),
    );
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  RoomAmenities.CLASS_NAMESPACE,
  RoomAmenities,
);
