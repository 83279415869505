/**
 *  *
 * @author Rishabh
 * @class SofitelProductBooking
 * @classdesc JS Class handling product booking component with HTML tag 'sofitel-product-booking'
 */
import { TagManager } from "@accor/ace-ui-core";

export default class SofitelProductBooking extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "sofitel-product-booking";

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    // widget using mutation observer as to add event listner once widget loads
    this.widgets = this.componentHost.querySelector(".product-booking-actions");
    let datePickerReady = 0;
    if (this.widgets) {
      const mutationConfig = {
        childList: true,
        subtree: true,
      };
      if (!this.widgets?.querySelector(".btn.btn-default")) {
        const observer = new MutationObserver(() => {
          datePickerReady++;
          observer.disconnect();
          if (datePickerReady == 1) {
            this.componentHost
              .querySelector(".product-booking-actions .btn.btn-default")
              ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
                this.tagManagerHandler(event);
              });
          }
        });
        observer.observe(this.widgets, mutationConfig);
      } else {
        datePickerReady++;
        if (datePickerReady == 1) {
          this.componentHost
            .querySelector(".product-booking-actions .btn.btn-default")
            ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
              this.tagManagerHandler(event);
            });
        }
      }
      this.componentHost
        .querySelectorAll(".product-booking-actions .ace-button .cmp-button")
        ?.forEach((element) => {
          element.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            this.tagManagerHandler(event);
          });
        });
    }
  }

  /**
   * Push data in tagmanager
   * @param {HTML} event -- target element
   */
  tagManagerHandler(event) {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const dataEvent = {
        event: "GA4event",
        eventName: "bloc_interact",
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          bloc_name: "opening hours and menu",
          bloc_interaction: event?.target?.innerText?.trim()?.toLowerCase(),
        },
      };
      TagManager.trackEvent(dataEvent);
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  SofitelProductBooking.CLASS_NAMESPACE,
  SofitelProductBooking,
);
